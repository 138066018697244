import _uniq from 'lodash.uniq'
import _difference from 'lodash.difference'
import moment from 'moment-timezone'
import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'
import { isAuthorized, addAlert } from '../../../utilities/helpers'
import { D_FIND_COUNTRY_CURRENCIES } from '../../../utilities/action-types'

import {
  findTransportationUserTransactionLogs,
  findTotalTransportationUserTransactionLogs,
  resendPayoutNotification
} from '../../../services/transportation-user-transaction-logs-service'

import { findTransportationOwners } from '../../../services/transportation-owners-service'

export default {
  name: 'transportation-accounts-payouts',
  props: {
    signedInUser: Object,
    forceRootViewRefresh: Function,
    selectedAccount: Object
  },
  data () {
    return {
      apiInProgress: false,
      searchId: '',
      agencyId: '',
      countryCurrencies: {},
      selectedVehicle: null,
      dateFromMenu: null,
      dateFrom: null,
      dateToMenu: null,
      dateTo: null,
      headers: [],
      payouts: [],
      totalPayouts: 0,
      agencies: [],
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 10,
        sortBy: 'dateUpdated'
      },
      moneyAccountLogos: {}
    }
  },
  computed: {
    computedDateFromFormatted () {
      return this.dateFrom ? formatDate(this.dateFrom) : ''
    },
    computedDateToFormatted () {
      return this.dateTo ? formatDate(this.dateTo) : ''
    },
    computedDateFromMax () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (toDateAsMoment && (!fromDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return toDateAsMoment.subtract(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    computedDateToMin () {
      const fromDateAsMoment = this.dateFrom ? moment(this.dateFrom, 'Y-MM-DD') : null
      const toDateAsMoment = this.dateTo ? moment(this.dateTo, 'Y-MM-DD') : null
      if (fromDateAsMoment && (!toDateAsMoment || fromDateAsMoment.valueOf() <= toDateAsMoment.valueOf())) {
        return fromDateAsMoment.add(1, 'day').format('Y-MM-DD')
      }
      return ''
    },
    currentSelectedAccount () {
      return this.$props.selectedAccount
    }
  },
  mounted () {
    this.agencies = this.$store.getters.getAgencies
    this.moneyAccountLogos = this.$store.getters.getMoneyAccountLogos

    if (this.$props.signedInUser) {
      this.isAdmin = isAuthorized(this.$props.signedInUser, 'admin')
      this.isTransporter = this.$props.signedInUser.roles.find((o) => (o.name === 'transporter'))
    }

    this.headers = [
      {
        text: 'Transaction ID',
        align: 'left',
        sortable: false
      },
      {
        text: 'Amount',
        align: 'left',
        sortable: true,
        value: 'amount'
      },
      {
        text: 'Paid Status',
        align: 'left',
        sortable: true,
        value: 'logStatus'
      },
      {
        text: 'Payout Account',
        align: 'left',
        sortable: false
      },
      {
        text: 'Payee Notified', // allow button to send if notification failed
        align: 'left',
        sortable: true,
        value: 'notified'
      },
      {
        text: 'Last Updated',
        align: 'left',
        sortable: true,
        value: 'dateUpdated'
      }
    ]

    this.searchPayouts()

    this.selectedAccountWatchHandle = this.$watch(() => { return this.$props.selectedAccount }, (newVal, oldVal) => {
      if (!this.apiInProgress && (this.$route.params.accountId || this.$route.params.accountId === 0)) {
        this.firstApiCalled = true
        this.searchPayouts()
      }
    })
  },
  methods: {
    goToTransactionReport (id) {
      this.$router.push({ name: 'transportation-accounts-payouts-payout', params: { payoutId: id } })
    },
    goToPayoutSchedule () {
      if (this.currentSelectedAccount) {
        this.$router.push({ name: 'transportation-accounts-payouts-schedule' })
      } else {
        alert('Please select an account first')
      }
    },
    goToPayout () {
      if (this.currentSelectedAccount) {
        this.$router.push({ name: 'transportation-accounts-payouts-payout', params: { payoutId: '0' } })
      } else {
        alert('Please select an account first')
      }
    },
    goToSalvage (itemId) {
      this.$router.push({ name: 'transportation-accounts-payouts-salvage', params: { payoutId: itemId } })
    },
    getPayoutStatusParams (payout) {
      switch (payout.logStatus) {
        case 'completed':
          return { color: 'success', icon: 'fa fa-check-circle' }
        case 'error':
          return { color: 'error', icon: 'fa fa-exclamation-triangle' }
        case 'timedout':
          return { color: 'warning', icon: 'fa fa-hourglass-end' }
        case 'pending':
          return { color: 'primary', icon: 'fa fa-hourglass-half' }
      }
    },
    async initCountryCurrencies (countryIds) {
      const countryIdsToQuery = []
      countryIds.forEach((countryId) => {
        if (!this.countryCurrencies[countryId]) {
          countryIdsToQuery.push(countryId)
        }
      })
      if (countryIdsToQuery.length) {
        const countryCurrencies = await this.$store.dispatch(D_FIND_COUNTRY_CURRENCIES, { where: { countryId: { inq: countryIdsToQuery } } })
        if (countryCurrencies && countryCurrencies.length) {
          countryCurrencies.forEach((currency) => {
            this.countryCurrencies[currency.countryId] = currency.currencySymbol
          })
        }
      }
    },
    async resendNotification (index) {
      if (confirm('Are you sure you want to re-send the payout report to this user?')) {
        let item
        try {
          item = this.payouts[index]
          item.apiInProgress = true
          this.payouts.splice(index, 1, item)
          await resendPayoutNotification(this.payouts[index].id)
          item = this.payouts[index]
          item.apiInProgress = false
          this.payouts.splice(index, 1, item)
          addAlert({
            message: `Successfully resent notification to payee`,
            type: 'success',
            transient: true
          })
        } catch (err) {
          item = this.payouts[index]
          item.apiInProgress = false
          this.payouts.splice(index, 1, item)
        }
      }
    },
    onPagination () {
      if (this.firstApiCalled || parseInt(this.$route.params.accountId, 10) === 0) {
        this.searchPayouts()
      }
    },
    marshallPayout (o) {
      o.amountLabel = `${o.logMetadata.currencySymbol ? o.logMetadata.currencySymbol : ''}${formatCurrency(o.amount / 100)}`
      o.logStatusParams = this.getPayoutStatusParams(o)
      o.dateUpdatedLabel = `${formatTime(o.dateUpdated)}, ${formatDate(o.dateUpdated)}`
      return o
    },
    async searchPayouts () {
      const { sortBy, descending, page, rowsPerPage } = this.pagination
      const offset = page === 1 ? 0 : (page * rowsPerPage) - rowsPerPage
      let params = {
        filter: {
          limit: rowsPerPage,
          offset,
          where: { logType: 'payout' },
          include: { transportationUserMomoAccount: 'transportationUserIdentifier' }
        }
      }
      if (this.agencyId) {
        params.filter.join = {
          passengerTrips: {
            relation: 'transportationProfile',
            scope: { where: { agencyId: this.agencyId } }
          }
        }
      }
      const accountId = this.currentSelectedAccount ? this.currentSelectedAccount.id : null
      if (accountId) {
        params.filter.where.transportationOwnerId = accountId
      }
      if (!sortBy) {
        params.filter.order = `dateUpdated ${descending ? 'DESC' : 'ASC'}`
      } else {
        params.filter.order = `${sortBy} ${descending ? 'DESC' : 'ASC'}`
      }
      if (this.dateFrom) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateUpdated: { gte: moment.utc(this.dateFrom, 'Y-MM-DD').toDate().toISOString() } })
      }
      if (this.dateTo) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ dateUpdated: { lte: moment.utc(this.dateTo, 'Y-MM-DD').add(23, 'hours').add(59, 'minutes').toDate().toISOString() } })
      }
      if (this.searchId) {
        if (!params.filter.where.and) {
          params.filter.where.and = []
        }
        params.filter.where.and.push({ id: { ilike: `%${this.searchId}%` } })
      }

      this.apiInProgress = true
      try {
        let [payouts, totalPayouts] = await Promise.all([
          findTransportationUserTransactionLogs(params.filter),
          findTotalTransportationUserTransactionLogs({ where: params.filter.where })
        ])

        if (payouts && payouts.length) {
          payouts = payouts.map(o => {
            return this.marshallPayout(o)
          })
          this.payouts = await this.initTransportationOwnerNamesDisplay(payouts)
        } else {
          this.payouts = []
        }

        this.totalPayouts = totalPayouts.count
        this.apiInProgress = false
      } catch (err) {
        console.log(err)
        this.apiInProgress = false
        addAlert({
          message: `Error in payouts search: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
          type: 'error'
        })
      }
    },
    async initTransportationOwnerNamesDisplay (payouts) {
      if (this.$props.selectedAccount) {
        payouts.forEach((o) => {
          o.transportationOwner = this.$props.selectedAccount
        })
        return payouts
      } else {
        try {
          if (payouts && payouts.length) {
            this.currentCompanies = this.currentCompanies || []
            let transportationOwnerIdsToQuery = payouts.map(o => o.transportationOwnerId)
            transportationOwnerIdsToQuery = _uniq(transportationOwnerIdsToQuery)
            transportationOwnerIdsToQuery = _difference(transportationOwnerIdsToQuery, this.currentCompanies.map(o => o.id))

            if (transportationOwnerIdsToQuery.length) {
              const transportationOwners = await findTransportationOwners({
                where: { id: { inq: transportationOwnerIdsToQuery } }
              })
              this.currentCompanies = this.currentCompanies.concat(transportationOwners)
            }

            payouts.forEach((payout, index) => {
              const transportationOwner = this.currentCompanies.find(o => o.id === payout.transportationOwnerId)
              if (transportationOwner) {
                payout.transportationOwner = transportationOwner
                payout.transportationOwnerCompanyName = transportationOwner.companyName
                payout.fullTransportationOwnerCompanyName = payout.transportationOwnerCompanyName
                payout.transportationOwnerCompanyName = payout.transportationOwnerCompanyName.length > 10 ? payout.transportationOwnerCompanyName.substring(0, 10) + '...' : payout.transportationOwnerCompanyName
              }
            })
          }
          return payouts
        } catch (err) {
          addAlert({
            message: `Error in company names check: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`,
            type: 'error'
          })
        }
      }
    }
  },
  beforeDestroy () {
    if (this.selectedAccountWatchHandle) {
      this.selectedAccountWatchHandle()
    }
  }
}
